import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Card } from '@mui/material';


export default function ProjectCarousel() {
    return (
        <Carousel autoPlay showStatus={false} infiniteLoop={true} interval={50000} size="small">
            <Card>
                <img src="project-1.gif" alt='hernan-avatar-2.png'/>
                <p className="legend">Legend 1</p>
            </Card>
            <Card>
                <img src="project-2.gif" alt='hernan-avatar-2.png' />
                <p className="legend">Legend 2</p>
            </Card>
            <Card>
                <img src="project-3.gif" />
                <p className="legend">Legend 3</p>
            </Card>
        </Carousel>
    );
}

