import React from "react";
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import GitHubIcon from '@mui/icons-material/GitHub';
import Link from '@mui/material/Link';
import YoutubeEmbed from "./YoutubeEmbed";
import { Button, CardActionArea, CardActions } from '@mui/material';

import "./styles/home.css";

export const Home = ({ darkMode }) => {

  return (

    <Container className={darkMode ? 'container-dark' : 'container-light'} >
      <Box className={darkMode ? 'avatar-box-dark' : 'avatar-box-light'}>
        <Avatar className={darkMode ? 'avatar-dark' : 'avatar-light'}
          alt="Hernan Pesantez"
          src="hernan-avatar-0.png"
        />
      </Box>

      <Box className={darkMode ? 'box-dark' : 'box-light'}>
        <Typography className={`box-about-text`}>
        Hi, I'm Hernan, a software engineer driven by a passion for technology and its ability to make a positive impact. Throughout my career, I've developed a wide range of projects, from small web applications to complex enterprise systems, and sharpened my skills in the process.

        <br></br>
        <br></br>

        I have a growing interest in artificial intelligence and machine learning as they offer immense potential for creating innovative solutions. I am eager to keep learning and exploring the exciting possibilities these fields have to offer.

        <br></br>
        <br></br>

        To track my progress and showcase my projects in web development and machine learning, I'll create a roadmap for you to follow along. I'll also share relevant and interesting information that I come across in these fields.

        <br></br>
        <br></br>

         Happy Coding!

        </Typography>
      </Box>


      <Divider className={darkMode ? 'divider-dark' : 'divider-light'} />

      <Typography className={`box-title-text`}>
        PROJECTS
      </Typography>
      {/* https://play-hub.herokuapp.com/ */}
      <Box className={darkMode ? 'box-project-dark' : 'box-project-light'}>
        <Card  className={darkMode ? 'card-project-dark' : 'card-project-light'}>
          <CardActionArea href="https://play-hub.herokuapp.com/">
            <CardMedia

              component="img"
              height="140"
              image="project-1.gif"
              alt="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
              Play-Hub
              </Typography>
              <Typography variant="body2" color="text.secondary">
              My first progressive web app, a simple but smart Tic-Tac-Toe game which utilizes a custom algorithm with a worst case of "Draw". This is a full-stack web application, where users can register and save their games.
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
          <Link href="https://github.com/hernanpesantez/">
              <GitHubIcon className={darkMode ? "icon-dark" : "icon-light"}/>
            </Link>
          </CardActions>
        </Card>
        <Card className={darkMode ? 'card-project-dark' : 'card-project-light'}>
          <CardActionArea href="https://course-chat.herokuapp.com/">
            <CardMedia
              component="img"
              height="140"
              image="project-2.gif"
              alt="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
              Course Chat
              </Typography>
              <Typography variant="body2" color="text.secondary">
              This app is intended to keep classmates connected, students can register, add courses and chat with their fellow classmates in real time. Currently I'm working on adding more features such as professor ratings and file sharing.
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
          <Link href="https://github.com/hernanpesantez/">
              <GitHubIcon className={darkMode ? "icon-dark" : "icon-light"}/>
            </Link>
          </CardActions>
        </Card>

        <Card className={darkMode ? 'card-project-dark' : 'card-project-light'}>
          <CardActionArea href="https://custom-auto-enroll.herokuapp.com/">
            <CardMedia
              component="img"
              height="140"
              image="project-3.gif"
              alt="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
              CUNY-Auto-Enroll
              </Typography>
              <Typography variant="body2" color="text.secondary">
              I created this program because I found myself spending lots of time checking for classes to open so I can register. This was a boring task indeed, so I created this program which constantly checked for open classes and registered them for me.
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Link href="https://github.com/hernanpesantez/">
              <GitHubIcon className={darkMode ? "icon-dark" : "icon-light"}/>
            </Link>
          </CardActions>
        </Card>

      </Box>

      {/* <Divider className={darkMode ? 'divider-dark' : 'divider-light'} /> */}

      {/* <Box className={darkMode ? 'box-dark' : 'box-light'}>
        <Typography variant="h5" mt={2} className={`box-about-text`}>
        I am determined to delve into the field of AI and expand my knowledge and skills in this exciting area. In the coming months, I plan to work on several cutting-edge projects that will allow me to immerse myself in the field and gain hands-on experience. For example, I might develop an AI-powered personal assistant that utilizes natural language processing and machine learning algorithms to help people manage their daily tasks. <br/><br/>

        These projects will not only allow me to learn new techniques and technologies, but they will also help me to better understand the ways in which AI is transforming our lives and the world around us. I am confident that these experiences will lay the foundation for a successful career in AI and enable me to make a meaningful contribution to this rapidly growing field.
        </Typography>
      </Box> */}

      <Divider className={darkMode ? 'divider-dark' : 'divider-light'} />

      <Typography className={`box-title-text`}>
        ARTICLES
      </Typography>

      <Box className={darkMode ? 'box-project-dark' : 'box-project-light'}>
        <Card  className={darkMode ? 'card-project-dark' : 'card-project-light'}>
          <CardActionArea  href="https://karpathy.github.io/2015/05/21/rnn-effectiveness/">
            <CardMedia
              component="img"
              height="140"
              image="DALL-E-1.png"
              alt="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                An Overview of Deep Learning
              </Typography>
              <Typography variant="body2" color="text.secondary">
              Provides an introduction to deep learning and its applications in computer vision and natural language processing
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size="small" color="primary" href="https://karpathy.github.io/2015/05/21/rnn-effectiveness/">
              READ
            </Button>
          </CardActions>
        </Card>
        <Card className={darkMode ? 'card-project-dark' : 'card-project-light'}>
          <CardActionArea href="https://arxiv.org/abs/1703.04474">
            <CardMedia
              component="img"
              height="140"
              image="DALL-E-2.png"
              alt="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
              Machine Learning: An Artificial Intelligence Approach
              </Typography>
              <Typography variant="body2" color="text.secondary">
              A modular framework for constructing new recurrent neural architectures, the Transition Based Recurrent Unit (TBRU), is presented. The TBRU allows for dynamic network connections and efficient multi-task learning. The approach, called DRAGNN, outperforms traditional methods in accuracy and efficiency for tasks such as dependency parsing and summarization.
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size="small" color="primary" href="https://arxiv.org/abs/1703.04474">
              READ
            </Button>
          </CardActions>
        </Card>

        <Card className={darkMode ? 'card-project-dark' : 'card-project-light'}>
          <CardActionArea href=" https://arxiv.org/abs/1701.07274">
            <CardMedia
              component="img"
              height="140"
              image="DALL-E-3.png"
              alt="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
              A Guide to Deep Reinforcement Learning
              </Typography>
              <Typography variant="body2" color="text.secondary">
              provides an overview of deep reinforcement learning, a subfield of machine learning that combines deep learning with reinforcement learning.
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size="small" color="primary" href="https://arxiv.org/abs/1701.07274">
              READ
            </Button>
          </CardActions>
        </Card>
      </Box>

        <Divider className={darkMode ? 'divider-dark' : 'divider-light'} />

        <Typography className={`box-title-text`}>
          DIVEOS
        </Typography>


        <Box className={darkMode ? 'box-project-dark' : 'box-project-light'}>
        <Card  className={darkMode ? 'card-project-dark' : 'card-project-light'}>
          <CardActionArea  href="https://youtu.be/aircAruvnKk">
          <YoutubeEmbed embedId="aircAruvnKk"/>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
              But what is a neural network? | Chapter 1, Deep learnin
              </Typography>
              <Typography variant="body2" color="text.secondary">
              What are the neurons, why are there layers, and what is the math underlying it?
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size="small" color="primary" href="https://karpathy.github.io/2015/05/21/rnn-effectiveness/">
              WATCH
            </Button>
          </CardActions>
        </Card>
        <Card className={darkMode ? 'card-project-dark' : 'card-project-light'}>
          <CardActionArea href="https://youtu.be/ukzFI9rgwfU">
          <YoutubeEmbed embedId="ukzFI9rgwfU"/>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
              Machine Learning Basics | What Is Machine Learning?
              </Typography>
              <Typography variant="body2" color="text.secondary">
              This Machine Learning basics video covers the fundamentals of Machine Learning, including its types and how it works. It also discusses its applications in various industries and its role as a subfield of AI.
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size="small" color="primary" href="https://youtu.be/ukzFI9rgwfU">
              WATCH
            </Button>
          </CardActions>
        </Card>

        <Card className={darkMode ? 'card-project-dark' : 'card-project-light'}>
          <CardActionArea href="https://www.youtube.com/watch?v=Wo5dMEP_BbI">
          <YoutubeEmbed embedId="Wo5dMEP_BbI" />
            <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Neural Networks from Scratch - P.1 Intro and Neuron Code
              </Typography>
              <Typography variant="body2" color="text.secondary">
              Building neural networks from scratch in Python introduction
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size="small" color="primary" href="https://youtu.be/Wo5dMEP_BbI">
              WATCH
            </Button>
          </CardActions>
        </Card>
      </Box>



    </Container>

  );
};
