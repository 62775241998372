
import React, { useState } from "react";
import "./App.css";
import NavBar from "./components/NavBar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Home } from "./components/Pages/Home";
import { Projects } from "./components/Pages/Projects";
import { Blog } from "./components/Pages/Blog";
import { Contact } from "./components/Pages/Contact";
import Footer from "./components/Pages/Footer";



function App() {

  const [darkMode, setDarkMode] = useState(true);
  var backGroundColor='';

  if (darkMode){
    backGroundColor = "background: #191f27 !important;"
  } else{
    backGroundColor = "background: #eee !important;"
  }


  document.body.style = backGroundColor;

  return (

    <>
    <Router>

      <NavBar darkMode={darkMode} setDarkMode={setDarkMode} />
        <div className={darkMode ? "dark" : "light"}>
          <Switch>
            <Route exact path="/" render={() => <Home darkMode={darkMode} />} />
            <Route path="/projects" render={() => <Projects darkMode={darkMode} />} />
            <Route path="/blog" render={() => <Blog darkMode={darkMode} />} />
            <Route path="/contact" render={() => <Contact darkMode={darkMode} />} />
          </Switch>

        </div>
      </Router>
      <Footer darkMode={darkMode} setDarkMode={setDarkMode} />

    </>



  );

}
export default App;
