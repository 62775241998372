import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import TwitterIcon from '@mui/icons-material/Twitter';
import MailIcon from '@mui/icons-material/Mail';
import { BottomNavigation } from '@mui/material';
import './styles/footer.css'

export default function Footer({ darkMode }) {
  return (

    <div className='root-footer'>
      <BottomNavigation className={darkMode ? "footer-dark" : "footer-light"}>
      <Typography className={darkMode ? "footer-text-dark" : "footer-text-light"}>
      {'Hernan Pesantez'}
        &nbsp;
        {'|'}
        &nbsp;
        {'©'}
        &nbsp;
        {new Date().getFullYear()}
        {'.'}
        &nbsp;
      </Typography>
      <Typography>
        <Link  href="https://www.linkedin.com/in/hernan-pesantez-856aba2b/">
          <LinkedInIcon className={darkMode ? "icon-dark" : "icon-light"} />
        </Link> &nbsp;
        <Link href="https://github.com/hernanpesantez/">
          <GitHubIcon className={darkMode ? "icon-dark" : "icon-light"}/>
        </Link> &nbsp;
        <Link href="https://twitter.com/HERNANPESANTEZ/">
          <TwitterIcon className={darkMode ? "icon-dark" : "icon-light"}  />
        </Link> &nbsp;
        <Link href="mailto:hernanpesantez@me.com">
          <MailIcon className={darkMode ? "icon-dark" : "icon-light"} />
        </Link>
      </Typography>
    </BottomNavigation>

    </div>




  );
}

