import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@material-ui/core/Typography';
import ProjectCarousel from './ProjectCarousel';
import './styles/projects.css'
export const Projects = ({ darkMode }) => {
  return (

    <Container className={`project-main-container ${darkMode ? 'box-dark' : 'project-box-light'}`} >

        <Box maxWidth={'md'} className={`project-box-about ${darkMode ? 'project-box-dark' : 'box-light'}`}>
          <Typography variant="h5" mt={2} className={`project-box-about-text`}>
          I'm currently working on updating my projects page to showcase all of my recent and ongoing projects.
          Thank you for visiting and stay tuned for updates!
          </Typography>
        </Box>

        <Divider className={`porject-box-divider ${!darkMode ? 'box-dark' : 'box-light'}`} />

        <ProjectCarousel />

      </Container>

  );
}

